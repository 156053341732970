import {defineStore} from 'pinia'
import {$larafetch} from "@/utils/$larafetch";
import {useNuxtApp} from "nuxt/app";
import {useNotification} from "@/composables/useNotification";
import {useEventBus} from '@vueuse/core'

export const useStatic = defineStore('static', {

    state: () => ({
        carrierTypes: [],
        carrierLegalForms: [],
        countries: [],
        years: [],
        activeYear: null,
    }),

    actions: {
        getStaticData() {
            $larafetch(useNuxtApp().$apiRoute('spa.basedata.statics.index'), {
                method: 'GET'
            }).then(response => {
                this.carrierTypes = response.carrierTypes
                this.carrierLegalForms = response.carrierLegalForms
                this.countries = response.countries
                this.years = response.years
                // find the active year in the years array
                this.activeYear = response.years.reduce((acc, year) => {
                    return year.is_active ? year : acc
                }, null)
                useEventBus('staticData').emit()

            }).catch(error => {
                const notification = useNotification()
                const app = useNuxtApp()
                if (error && error.response && error.response.status === 422) {
                    notification.error(app.$i18n.t('invalidRequestData'))
                } else if (error && error.response && error.response.status === 404) {
                    notification.error(app.$i18n.t('modelDoesNotExist'))
                }
                throw error
            })
        }
    }
})